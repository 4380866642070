import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import styled from "styled-components"

const StyledContainerWrpaper = styled.div`
  padding: 40px 0;
  text-align: center;
`
const MessageSend = () => {
  return (
    <Layout>
      <SEO title="Home"/>
      <Container maxWidth={"lg"}>
        <StyledContainerWrpaper>
          <Typography>Wiadomośc wysłana!</Typography>
          <Typography>Dziękujemy za skorzystanie z formularza kontaktowego! Twoja wiadomość na pewno pozytywnie wpłynie
            na rozwój aplikacji.</Typography>
        </StyledContainerWrpaper>
      </Container>
    </Layout>
  )
}

export default MessageSend
